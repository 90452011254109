import React from "react"
import Manager from "../components/manager";
import ESafetyComponent from "../components/esafety";

export default function ESafety() {
    return (
        <Manager name="login" title="eSafety">
          <ESafetyComponent/>
        </Manager>
    );
}


